import axios from 'axios'

export const getCpf = async (params) => {
    return await axios.request({
        method: 'GET',
        params,
        url: process.env.REACT_APP_HELPER_GET_CPF,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });
}

export const getCnpj = async (params) => {
    return await axios.request({
        method: 'GET',
        params,
        url: process.env.REACT_APP_HELPER_GET_CNPJ,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });
}



