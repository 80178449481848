import axios from 'axios'
import {CREAT_CONTRACT, SEARCH_CITY} from '../helpers/url_helper'


export async function generateContract(payment) {

    const totalAmount = payment.total

    const info = {
        polo: {
            id: payment.enrollment.idPolo,
            contratoDocx: {
                filepath: '/home/softeo/www/ibrep/public/contratos/' + payment.enrollment.contratoFile
            }
        },
        customer: {
            name: payment.payer.name,
            email: payment.payer.email,
            whatsapp: payment.payer.phone_complete,
            cpf: payment.payer.cpf_cnpj,
            address: {
                ...payment.payer.address,
                state: {
                    nome: payment.payer.address.state
                }
            }
        },
        amount: totalAmount
    }

    return await axios.post(CREAT_CONTRACT, info)

}

export async function searchCity(term, state) {

    return await axios.get(SEARCH_CITY, {
        params: {
            uf: state,
            cidade: term
        }
    })
}
