import React, { useRef } from 'react'
import InputMask from 'react-input-mask'


export const InputCnpj  = React.forwardRef(({
    type = 'tel',
    name,
    value,
    className,
    autoComplete,
    required,
    handleChange,
    onBlur
}, ref) =>  {
    return (
        <InputMask mask="99.999.999/9999-99"
                   type={type}
                   name={name}
                   value={value}
                   maskChar={null}
                   className={
                       `form-control ${ 
                       className}`
                   }
                   ref={ref}
                   autoComplete={autoComplete}
                   required={required}
                   onBlur={onBlur}
                   onChange={(e) => handleChange(e)} />
    )

})
