import React from 'react'
import InputMask from 'react-input-mask'


export const InputCep = React.forwardRef(({
                                              name,
                                              value,
                                              className,
                                              autoComplete,
                                              required,
                                              onChange,
                                              readOnly,
                                                invalid
                                          }, ref) => {

    return (
        <InputMask
            ref={ref}
            mask="99999-999"
            maskChar={null}
            value={value}
            readOnly={readOnly}
            name={name}
            className={
                `form-control ${
                    className}`
            }
            autoComplete={autoComplete}
            required={required}
            invalid={invalid}
            onChange={(e) => onChange(e)}/>
    )

})
