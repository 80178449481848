// ** React Imports
import {Fragment, useState} from 'react'

// ** Third Party Components
import { useForm, Controller } from 'react-hook-form'
import { ChevronLeft, ChevronRight } from 'react-feather'

// ** Reactstrap Imports
import { Form, Label, Input, Row, Col, Button, FormFeedback } from 'reactstrap'

import {InputCep} from './InputCep'
import Select from 'react-select'
import {BrazilianStates} from '../lists/states'
import axios from 'axios'

import classnames from 'classnames'
import {selectThemeColors} from '../helpers/colors'
import {searchCity} from '../services/ContratoService'
import AsyncSelect from 'react-select/async'
const Address = ({trigger, setStateUf, stateUf, city, cityOptions, setCityOptions, control, errors, setValue, clearErrors, required = false , addressFields = {
    zipcode: 'address[zipcode]',
    address: 'address[street]',
    address_number: 'address[number]',
    addressline2: 'address[complement]',
    district: 'address[district]',
    city: 'address[city]',
    state: 'address[state]',
} }) => {

    const [selectedDBVal, setSelectedDBVal] = useState(city)
    const [query, setQuery] = useState(city?.value)

    const handleDBChange = value => {
        setSelectedDBVal(value)
        setValue(addressFields.city, value)
        trigger()
    }

    const handleDBInputChange = newValue => {
        setQuery(newValue)
    }

    const loadOptionsDB = async () => {
        const cities = await searchCity(query, stateUf)
        return cities.data;
    }

    const handleStateChange = (e) => {

        setValue(addressFields.city, null)
        setSelectedDBVal(null)
        setStateUf(e.value)
        setCityOptions(null)
        setValue(addressFields.state, BrazilianStates.filter(uf => e.value === uf.value)[0])
    }



    const handleCep = (e) => {
        setValue(addressFields.zipcode, e.target.value)
        if (e.target.value.length === 9) {

            fetch(`https://viacep.com.br/ws/${e.target.value}/json/`).then((res) => {

                res.json().then((data) => {
                    setValue(addressFields.address, data.logradouro)
                    setValue(addressFields.addressline2, data.complemento)
                    setValue(addressFields.district, data.bairro)
                    setValue(addressFields.city, data.localidade)
                    clearErrors(addressFields.address)
                    clearErrors(addressFields.addressline2)
                    clearErrors(addressFields.district)
                    clearErrors(addressFields.city)

                    BrazilianStates.map(
                        (option) => {
                            if (option.value === data.uf) {
                                setValue(addressFields.state, option, true)
                                setStateUf(data.uf)
                                clearErrors(addressFields.state)
                            }
                        }
                    )

                })
                trigger()


            })
        }
    }

    return (
        <Row>
            <Col sm='12' className='mb-1'>
                <Label className={` ${classnames(required ? 'required' : '')} form-label`} for='zipcode'>
                    CEP*
                </Label>
                <Controller
                    id='zipcode'
                    name={addressFields.zipcode}
                    control={control}
                    render={({ field }) => <InputCep
                        className={errors.address?.zipcode && 'is-invalid'}
                        invalid={errors.address?.zipcode && true} {...field} onChange={(e) => handleCep(e)}
                    />}
                />
                {errors.address?.zipcode && <FormFeedback>{errors.address?.zipcode.message}</FormFeedback>}
            </Col>
            <Col sm='12' md={9} className='mb-1'>
                <Label  className={` ${classnames(required ? 'required' : '')} form-label`} for='address'>
                    Rua, Avenida, Travessa*
                </Label>
                <Controller
                    id='address'
                    name={addressFields.address}
                    control={control}
                    render={({ field }) =>
                        <Input invalid={errors?.address?.[addressFields.address] && true} {...field} />}
                />
                {errors.address?.[addressFields.address] && <FormFeedback>{errors.address?.[addressFields.address].message}</FormFeedback>}
            </Col>
            <Col sm='12' md={3} className='mb-1'>
                <Label  className={` ${classnames(required ? 'required' : '')} form-label`} for='address_number'>
                    Número*
                </Label>
                <Controller
                    id='address_number'
                    name={addressFields.address_number}
                    control={control}
                    render={({ field }) =>
                        <Input type="tel" invalid={errors.address?.number && true} {...field} />}
                />
                {errors.address?.number && <FormFeedback>{errors.address?.number?.message}</FormFeedback>}
            </Col>
            <Col sm='12' md={6} className='mb-1'>
                <Label className='form-label' for='addressline2'>
                    Complemento
                </Label>
                <Controller
                    id='addressline2'
                    name={addressFields.addressline2}
                    control={control}
                    render={({ field }) => <Input invalid={errors.addressline2 && true} {...field} />}
                />
                {errors.addressline2 && <FormFeedback>{errors.addressline2.message}</FormFeedback>}
            </Col>
            <Col sm='12' md={6} className='mb-1'>
                <Label  className={` ${classnames(required ? 'required' : '')} form-label`} for='district'>
                   Bairro*
                </Label>
                <Controller
                    id='district'
                    name={addressFields.district}
                    control={control}
                    render={({ field }) => <Input invalid={errors.address?.district && true} {...field} />}
                />
                {errors.address?.district && <FormFeedback>{errors.address?.district?.message}</FormFeedback>}
            </Col>
            <Col md='6' className='mb-1'>
                <Label  className={` ${classnames(required ? 'required' : '')} form-label`} for='state'>
                    Estado*
                </Label>
                <Controller
                    id='state'
                    name={addressFields.state}
                    control={control}
                    render={({ field }) =>
                        <Select
                            required={required}
                            theme={selectThemeColors}
                            className={errors.address?.state && 'is-invalid'}
                            {...field}
                            onChange={handleStateChange}
                            options={BrazilianStates} invalid={errors.address?.state && true} />}
                />
                {errors.address?.state && <FormFeedback>Selecione um estado</FormFeedback>}
            </Col>

            {stateUf &&
            <Col className='mb-1' md='6' sm='12'>
                <Label className='form-label'>Cidade*</Label>
                <Controller
                    id={addressFields.city}
                    name={addressFields.city}
                    control={control}
                    render={({ field }) =>
                        <AsyncSelect
                            {...field}
                            required={true}
                            value={selectedDBVal}
                            placeholder={"Digite para buscar"}
                            name={addressFields.city}
                            className='react-select'
                            classNamePrefix='select'
                            onChange={handleDBChange}
                            theme={selectThemeColors}
                            loadOptions={loadOptionsDB}
                            onInputChange={handleDBInputChange}
                            defaultValue={cityOptions?.filter(c => c.value === city.value)}
                            defaultOptions={cityOptions}
                        />
                }
                />

                {errors.address?.city && <FormFeedback>Selecione uma cidade da lista</FormFeedback>}
            </Col>
            }
        </Row>
    )
}

export default Address
