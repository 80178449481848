import React, {useRef } from 'react'
import InputMask from 'react-input-mask'


export default function InputCpf({
    type = 'tel',
    name,
    value,
    className,
    autoComplete,
    required,
    handleChange,
    onBlur
}) {
    const input = useRef()


    return (
        <InputMask mask="999.999.999-99"
                   type={type}
                   name={name}
                   maskChar={null}
                   value={value}
                   className={
                       `form-control mb-3${ 
                       className}`
                   }
                   ref={input}
                   autoComplete={autoComplete}
                   required={required}
                   onBlur={onBlur}
                   onChange={(e) => handleChange(e)} />
    )

}
