import { Home as Homeicon } from 'react-feather'

const Home = () =>

{

    return <h1><Homeicon size={18} /> Home </h1>
}

export default Home
