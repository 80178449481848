import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

// Import Routes
import { publicRoutes } from "./routes/";

// Import scss
import "./assets/scss/theme.scss";


export default function App() {
    return (
        <Router>
                <Routes>
                    { publicRoutes.map((route, idx) =>
                    <Route key={idx} path={route.path} element={route.component} />
                )}
                </Routes>
        </Router>
    );
}
