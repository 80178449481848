import axios from 'axios'

export const updateContact = async (data) => {


    return await axios.request({
        method: 'POST',
        data:  data,
        url: process.env.REACT_APP_UPDATE_CONTACT,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });


}
export const updateContract = async (data) => {


    return await axios.request({
        method: 'POST',
        data:  data,
        url: process.env.REACT_APP_UPDATE_CONTRACT,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });

}



