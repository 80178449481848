import {BrazilianStates} from '../lists/states'

export function handleCep(cep) {

         return fetch(`https://viacep.com.br/ws/${cep}/json/`).then((res) => {

           return res.json().then((data) => {
                BrazilianStates.map(
                    (option) => {
                        if (option.value === data.uf) {
                           data['state'] = option.label
                        }
                    }
                )

               fetch(`https://viacep.com.br/ws/${cep}/json/`).then((res) => {})

                return data

            }).catch(error => {return error})

        })
}

export function handleCidade(estado, cidade) {
    return fetch(process.env.REACT_APP_HELPER_GET_CIDADE + '?cidade='+cidade+'&estado=' + estado).then(
        res => {
            return res.json().then((data) => {
                return data.data
            })
        }
    )
}
