import {ArrowRight, Bookmark, CheckSquare, MapPin, ShoppingCart, User} from 'react-feather'
import {Link, useParams, useSearchParams} from 'react-router-dom'
import {db} from '../../helpers/firebase_helper'
import {doc, onSnapshot} from "firebase/firestore";
import {useEffect, useState} from 'react'
import './style.scss'
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader, CardTitle,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    NavItem,
    NavLink,
    PopoverHeader,
    Row,
    Spinner,
    TabContent,
    Table,
    TabPane,
    UncontrolledPopover
} from 'reactstrap'
import {Controller, useForm} from 'react-hook-form'
import logo from '../../assets/images/ibrep-logo.png'
import {cpf} from 'cpf-cnpj-validator'
import Address from '../../components/Address'
import InputCpf from '../../components/InputCpf'
import {InputTel} from '../../components/InputTel'
import classnames from 'classnames'
import {generateContract, searchCity} from '../../services/ContratoService'
import CreditCard from '../../components/CreditCard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import * as iuguService from '../../services/IuguService'
import * as teopayService from '../../services/teopayService'
import {faCopy} from '@fortawesome/free-solid-svg-icons'
import {useCopyToClipboard} from 'usehooks-ts'
import * as RedeService from '../../services/RedeService'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import valid from 'card-validator'
import {BrazilianStates} from '../../lists/states'
import * as BitrixService from '../../services/BitrixService'
import * as OraculoService from '../../services/OraculoService'
import Select from "react-select";
import {SexoOptions} from "../../lists/sexo";
import {useTranslation} from 'react-i18next';
import {getCpf} from '../../services/HelperService'
import Pagador from './Pagador'
import {enroll} from "../../services/OraculoService";
import {BurgerFade, BurgerSpin} from "react-icons-animated";


const cpfErrosMessage = {
    "Parametro Invalido.": "CPF ou data de nascimento informados inválido. Por favor, confira os dados e tente novamente",
    "Data de Nascimento não informada para Consulta On-line": "Data de Nascimento não informada",
    "CPF Inválido.": "CPF Inválido",
    "Data Nascimento Inválida": "Data de nascimento inválida",
    "Consulta não retornou": "ERRO",
    "IP de origem nao identificado.": "ERRO ",
    "IP de origem nao permitido.": "ERRO",
    "Token Inválido ou sem saldo para a consulta.": "ERRO",
    "Timeout.": "ERRO",
    "Nao foi possivel obter o captcha": "ERRO",
    "Erro ao obter retorno.": "ERRO",
    "Nao foi possivel conectar ao Proxy.": "ERRO",
    "Limite Excedido": "ERRO",
    "Token Bloqueado. Aguarde alguns instantes ou entre em contato.": "ERRO",
}
const Checkout = () => {
    const {code} = useParams()
    const {t} = useTranslation()
    const [activeTab, setActiveTab] = useState(1)
    const [contratoUrl, setContratoUrl] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [value, copy] = useCopyToClipboard()
    const [dealId, setDealId] = useState(0)
    const [data, setData] = useState({})
    const [stateUf, setStateUf] = useState("")
    const [errorFeedback, setErrorFeedback] = useState(null)
    const [gerandoBoleto, setGerandoBoleto] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cpfEncontrado, setCpfEncontrado] = useState(false)
    const [city, setCity] = useState()
    const [cityOptions, setCityOptions] = useState([])
    const [pagador, setPagador] = useState({})

    const [searchParams, setSearchParams] = useSearchParams();
    const handlePagador = (pag) => {
        setPagador(pag)
        setValue('billingPayer', pag)
        trigger()
    }



    yup.addMethod(yup.string, 'creditCardType', function (errorMessage) {
        return this.test(`test-card-type`, errorMessage, function (creditCardNumber) {
            const {path, createError} = this
            if (creditCardNumber) {
                return (
                    valid.number(creditCardNumber).isValid || createError({path, message: errorMessage})
                )
            } else {
                return true
            }

        })
    })


    onSnapshot(doc(db, "payments", code), (doc) => {
        const data = doc.data()

        if (data) {
            if (data.status === 'paid') {
                setActiveTab(5)
            }

            if (data.oraculo?.response?.idmatricula > 0) {
                setActiveTab(5)
            }

            if (activeTab > 2) {
                setData(data)
            }

        }


    });

    const schema = yup.object().shape({
        address: yup.object().shape({
            zipcode: yup.string().required('CEP é obrigatório'),
            street: yup.string().required('Rua é obrigatório'),
            district: yup.string().required('Bairro é obrigatório'),
            state: yup.mixed().required("Este campo é obrigatório"),
            city: yup.mixed().required("Este campo é obrigatório"),
            number: yup.string().required('Número é obrigatório')
        }),
        name: yup.string().required('Nome Completo deve ser preenchido'),
        email: yup.string().email('Deve ser um e-mail válido').required('E-mail deve ser preenchido'),
        phone_complete: yup.string().required('Celular é obrigatório').min(13, 'número inválido'),
        sexo: yup.mixed().required("Este campo é obrigatório"),
        data_nascimento: yup.string().required('Data de nascimento é obrigatória'),
        mesmo_pagador: yup.boolean(),
        billingPayer: yup
            .object()
            .when('mesmo_pagador',{
                is: (value) => {
                    return value === false
                },
                then: (schema) => schema.shape({
                    documento: yup.string().required('Este campo é obrigatório'),
                    email: yup.string().email("Deve ser um e-mail válido").required('Este campo é obrigatório').label('E-mail'),
                    name: yup.string().required('Este campo é obrigatório'),
                    address: yup.object().shape({
                        cep: yup.string().required('CEP é obrigatório'),
                        numero: yup.string().required('Número é obrigatório'),
                    })
                }),
                otherwise: (schema) => schema.optional()
            })
    })

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        clearErrors,
        setError,
        trigger,
        register,
        watch,
        getValues,
        formState: {errors, isValid, isSubmitting}
    } = useForm({
        defaultValues: {
            terms_check: false,
            terms_sign: false,
            mesmo_pagador: true
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema)
    })

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    })


    useEffect(() => {

        db.doc('payments/' + code)
            .get()
            .then(async (res) => {
                const doc = res.data()
                setDealId(doc.id)
                setData(res.data())

                reset({
                    ...res.data().payer
                })

                if (doc.payer) {
                    setValue('address[state]', BrazilianStates.filter(b => b.value === doc.payer.address.stateUf)[0])
                    setValue('sexo', SexoOptions.filter(s => s.value === doc.payer.sexo)[0])
                    setStateUf(doc.payer.address?.stateUf)
                    handleCpfChange(res.data().payer.cpf_cnpj)

                }


                if (doc.payer?.address?.city) {
                    const citiesOptions = await searchCity(doc.payer.address?.city, doc.payer.address.stateUf)
                    if (citiesOptions) {
                        setValue('address[city]', citiesOptions.data[0])
                        setCity(citiesOptions.data[0])
                        setCityOptions(citiesOptions.data)
                    }


                }
                if (doc.contrato) {
                    setContratoUrl(doc.contrato)
                }

                if (doc.status === 'paid' || doc.status === 'Pago') {
                    setActiveTab(5)
                }
                if (doc.status === 'test') {
                    setActiveTab(3)
                }

                trigger()

            })
    }, [code])

    const onError = (error) => {
        setErrorFeedback(error)

    }
    const handleCreditCard = async (submittedData) => {

        const index = submittedData.index
        await RedeService.post(
            {
                code: code, ...submittedData,
                index,
                amount: data.invoices[index].total * 100
            })

    }


    const generateInvoice = async (index) => {
        setGerandoBoleto(true)
        //Generate IUGU invoice
        const iuguInvoice = {
            email: data.payer.email,
            due_date: data.invoices[index].due_date,
            invoice_index: index,
            ensure_workday_due_date: true,
            items: data.invoices[index].items,
            payable_with: ['bank_slip'],
            payer: data.payer,
            custom_variables: [{
                name: 'Deal Id',
                value: data.id
            }],
            external_reference: code
        }

        await iuguService.post(iuguInvoice)
        setGerandoBoleto(false)
    }
    const generatePix = async (index) => {
        setGerandoBoleto(true)
        //Generate IUGU invoice
        const payload = {
            email: data.payer.email,
            due_date: data.invoices[index].due_date,
            ensure_workday_due_date: true,
            invoice_index: index,
            items: data.invoices[index].items,
            payable_with: ['Pix'],
            payer: data.payer,
            custom_variables: [{
                name: 'Deal Id',
                value: data.id
            }],
            external_reference: code,
        }

        await teopayService.post(payload)
        setGerandoBoleto(false)
    }


    const onSubmit = (payerData, e) => {

        const info = {
            ...data,
            payer: {
                ...data.payer,
                name: payerData.name,
                cpf_cnpj: payerData.cpf_cnpj,
                email: payerData.email,
                phone_complete: payerData.phone_complete,
                sexo: payerData.sexo?.value,
                data_nascimento: payerData.data_nascimento,
                address: {
                    ...payerData.address,
                    city: payerData.address.city.label,
                    state: payerData.address.state.label,
                    stateUf: payerData.address.state.value

                }
            },
            pagador
        }


        db.doc('payments/' + code).update({
                payer: info.payer,
                billingPayer: payerData.mesmo_pagador ? null : pagador,
                mesmo_pagador: typeof payerData.mesmo_pagador === 'undefined'
            },
            {merge: true})


        generateContract(info).then((contrato) => {
            setContratoUrl(contrato.data.url)
            setContratoUrl(contrato.data.url)
            db.doc('payments/' + code).update({contrato: contrato.data.url}, {merge: true})
        }).catch((e) => {
            if (e.hasOwnProperty('error')) {
                alert(e.error.message)
            }
            console.log(e)
        })

        BitrixService.updateContact(info)
        setData(info)
        setActiveTab(2)


    }
    const handleContractAccept = () => {

        db.doc('payments/' + code).update({
            terms: {
                accepted: true,
                date: Date.now()
            }
        }, {merge: true})


        //Salvar contrato no bitrix
        BitrixService.updateContract({code: code}).then(() =>
            {

                if(data.bolsa) {

                    setLoading(true)
                    OraculoService.enroll(data.id).then(() => {
                            setActiveTab(5)
                            setLoading(false)
                        }
                    ).catch((error) => console.log(error))
                        .finally(() => setLoading(false))

                }
                else {
                    setActiveTab(3)
                }

            }

        )




    }

    const handleCpfChange = (input) => {
        setValue('cpf_cnpj', input)
        cpf.isValid(input) ? clearErrors('cpf_cnpj') : setError('cpf_cnpj', {type: 'custom', message: 'CPF inválido'})
    }
    const completeCpf = () => {
        setLoading(true)
        const document = watch('cpf_cnpj')
        const nascimento = watch('data_nascimento')
        setError('cpf_nascimento', false)

        cpf.isValid(document) ? clearErrors('cpf_cnpj') : setError('cpf_cnpj', {
            type: 'custom',
            message: 'CPF inválido'
        })

        if (cpf.isValid(document)) {
            getCpf({
                cpf: document,
                data_nascimento: nascimento
            }).then(data => {
                setLoading(false)

                if (data.data?.status) {
                    setCpfEncontrado(true)
                    setValue('name', data.data?.result?.nome_da_pf)
                    trigger()
                } else {

                    if(data.data?.message === 'Consulta não retornou') {
                        setCpfEncontrado(true)
                        return
                    }
                    if (cpfErrosMessage[data.data?.message] === 'ERRO') {
                        setCpfEncontrado(false)
                    } else {
                        setError('cpf_nascimento', cpfErrosMessage[data.data?.message])
                    }
                    setCpfEncontrado(false)

                }

            })
                .catch(error => {
                    console.log(error)
                    setCpfEncontrado(true)
                    setLoading(false)
                    setError('cpf_nascimento', 'CPF ou data de nascimento inválidos')
                })
        } else {
            setCpfEncontrado(false)
            setLoading(false)
        }

    }
    const handleTermsChange = (e, field) => {
        setData({...data, [field]: e.target.checked})
        setValue(field, e.target.checked)
        if (!e.target.checked) {
            setError(field, {type: 'custom', message: 'Campo Obrigatório'})
        } else {
            clearErrors(field)
        }
    }
    const Bio = () => {
        return <Row className={'mt-5'}>
            <Col className="text-center align-items-center justify-content-center d-flex">
                <div className="d-flex bg-soft-secondary rounded-5  align-items-center justify-content-center pt-3">
                    {data.user.PERSONAL_PHOTO && <div className="d-block">
                        <img src={data.user.PERSONAL_PHOTO} className="rounded-5" width={'50px'}/>
                        <p>{data.user.NAME} {data.user.LAST_NAME}</p>
                    </div>

                    }

                    <blockquote className="align-content-center w-75">
                        {data.user.UF_USR_1698356473723}
                    </blockquote>
                </div>


            </Col>
        </Row>
    }

    const Carrinho = ({col = 4, title = true}) => {
        const totalAmount = data.total


        return <Col md={col}>
            <Card className="my-auto mb-5">
                {title && <CardHeader><h5><ShoppingCart size={20}/> Carrinho </h5></CardHeader>}
                <CardBody>
                    <Row>
                        <Col>

                            <Table striped>
                                <thead>
                                <tr>
                                    <th>Fatura</th>
                                    <th>Valor</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.invoices.map((invoice, i) =>
                                    invoice.items?.map((c, k) =>
                                        <tr key={k}>
                                            <td>{c.description}<br/>
                                                {invoice.rede ?
                                                    <>

                                                        {invoice.rede?.installments ? 'em ' + invoice.rede?.installments + ' parcelas' : 'a vista'}
                                                    </> : <>
                                                    </>
                                                }
                                            </td>
                                            <td>{data.bolsa ?   "R$ 0,00" : formatter.format(c.price_cents / 100)}</td>
                                            <td>{t(invoice.status)}</td>
                                        </tr>)
                                )}
                                </tbody>
                            </Table>
                            <Row className='d-flex justify-content-end p-1'>
                                <Col md="auto" sm="auto" xl="auto">
                                    <h6>Valor Total: {data.bolsa ? "R$ 0,00": formatter.format(totalAmount)}</h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {data.combo
                && <Card md={12}>
                    <CardTitle><CardHeader><h5><Bookmark size={20}/> Cursos Extras </h5></CardHeader></CardTitle>
                    <CardBody>
                        <ul>
                            {
                            data.combo.map(c => {
                                return <li>{c.curso}</li>
                            })
                            }
                        </ul>
                    </CardBody>

                </Card>
            }
        </Col>
    }


    if (data?.invoices) {
        return <Container className={'px-3 bg-white py-3 rounded-5 my-5'}>
            <Row className="justify-content-center">
                <Col xs={6} sm={3} className="ps-3 my-sm-2">
                    <img src={logo} alt={'Ibrep  -  Escola Imobiliária'} className="img-fluid"/>
                </Col>
                {data.user?.UF_USR_1698356473723 && <Bio/>}

            </Row>

            <Row className="pt-5 mb-5">
                <Col md={activeTab !== 5 ? 8 : 12}>
                    <Row>
                        <div id="progrss-wizard" className="twitter-bs-wizard">
                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                <NavItem>
                                    <NavLink>
                                        <span className="step-number">01</span>
                                        <span className="step-title">Dados do Aluno</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink>
                                        <span className="step-number">02</span>
                                        <span className="step-title">Contrato</span>
                                    </NavLink>
                                </NavItem>
                                <>
                                    {data.invoices.length > 1 ? <>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === 3})}>
                                                    <span className="step-number">03</span>
                                                    <span className="step-title">Pagamento Entrada</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === 4})}>
                                                    <span className="step-number">04</span>
                                                    <span className="step-title">Pagamento</span>
                                                </NavLink>
                                            </NavItem>
                                        </>

                                        : data.bolsa === false && <NavItem>
                                            <NavLink
                                                className={classnames({active: activeTab === 3})}>
                                                <span className="step-number">03</span>
                                                <span className="step-title">Pagamento</span>
                                            </NavLink>
                                        </NavItem>}
                                    <NavItem>
                                        <NavLink className={classnames({active: activeTab === 5})}>
                                            <span className="step-number">05</span>
                                            <span className="step-title">Confirmação</span>
                                        </NavLink>
                                    </NavItem>
                                </>
                            </ul>
                        </div>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                                <TabPane tabId={1}>
                                    <Card>
                                        <Form onSubmit={handleSubmit(onSubmit, onError)}>
                                            <CardHeader><h5><User size={16}/> Dados do aluno </h5></CardHeader>
                                            <CardBody>
                                                <Row className={'mt-2'}>
                                                    <Col sm={12} md={8}>
                                                        <Label>CPF*</Label>
                                                        <Controller
                                                            name={'cpf_cnpj'}
                                                            control={control}
                                                            render={({field}) => (<InputCpf id="cpf_cnpj"
                                                                                            type={'text'}
                                                                                            handleChange={(e) => handleCpfChange(e.target.value)}
                                                                                            invalid={errors.cpf_cnpj && true} {...field}/>)}
                                                        />
                                                        {errors.cpf_cnpj && (<FormFeedback
                                                            className="d-block">{errors.cpf_cnpj?.message}</FormFeedback>)}
                                                    </Col>

                                                    <Col sm={12} md={4}>
                                                        <Label for="data_nascimento">Data de nascimento</Label>
                                                        <Controller
                                                            name={'data_nascimento'}
                                                            control={control}
                                                            render={({field}) => (
                                                                <Input type="date"
                                                                       invalid={errors.data_nascimento && true} {...field}
                                                                       required={true}/>
                                                            )}
                                                        />
                                                        {errors.data_nascimento && (<FormFeedback
                                                            className="d-block">{errors.data_nascimento?.message}</FormFeedback>)}
                                                    </Col>
                                                    {!cpfEncontrado && <Col className="mt-3">
                                                        <Button
                                                            onClick={completeCpf}
                                                            disabled={loading}
                                                        > {loading ? 'Carregando informações' : 'Continuar'} </Button>
                                                    </Col>}

                                                    {errors.cpf_nascimento && (<FormFeedback
                                                        className="d-block">{errors.cpf_nascimento?.message}</FormFeedback>)}
                                                </Row>

                                                {cpfEncontrado &&
                                                    <>
                                                        <Row className={'mt-2'}>
                                                            <Col md={6} sm={12}>
                                                                <Label>Nome Completo*</Label>
                                                                <Controller
                                                                    name={'name'}
                                                                    control={control}
                                                                    render={({field}) => (
                                                                        <Input id="name" type={'text'}
                                                                               maxLength={60}
                                                                               invalid={errors.name && true}
                                                                               {...field}/>)}
                                                                />
                                                                {errors.name && (<FormFeedback
                                                                    className="d-block">{errors.name?.message}</FormFeedback>)}
                                                            </Col>
                                                        </Row>
                                                        <Row className={'mt-3'}>
                                                            <Col md={6} sm={12}>
                                                                <Label>Celular*</Label>
                                                                <Controller
                                                                    name={'phone_complete'}
                                                                    control={control}
                                                                    render={({field}) => (
                                                                        <InputTel id="phone_complete" type={'text'}
                                                                                  invalid={errors.phone_complete && true} {...field}/>)}
                                                                />
                                                                {errors.phone_complete && (<FormFeedback
                                                                    className="d-block">{errors.phone_complete.message}</FormFeedback>)}
                                                            </Col>
                                                            <Col sm={12} md={6} className="mt-1">
                                                                <Label>E-mail*</Label>
                                                                <Controller
                                                                    name={'email'}
                                                                    control={control}
                                                                    render={({field}) => (
                                                                        <Input id="email" type={'email'}
                                                                               invalid={errors.email && true} {...field}/>)}
                                                                />
                                                                {errors.email && (<FormFeedback
                                                                    className="d-block">{errors.email.message}</FormFeedback>)}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-2">
                                                            <Col sm={12} md={6}>
                                                                <Label for="sexo">Sexo*</Label>
                                                                <Controller
                                                                    name={'sexo'}
                                                                    control={control}
                                                                    render={({field}) => (
                                                                        <Select
                                                                            options={[{
                                                                                label: 'Feminino',
                                                                                value: 'F'
                                                                            }, {label: 'Masculino', value: 'M'}]}
                                                                            invalid={errors.sexo && true} {...field}
                                                                            required={true}/>)}
                                                                />

                                                                {errors.sexo && (<FormFeedback
                                                                    className="d-block">{errors.sexo.message}</FormFeedback>)}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }

                                            </CardBody>
                                            {cpfEncontrado &&
                                                <>
                                                    <CardHeader><h5><MapPin size={16}/> Endereço Do Aluno </h5>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Address
                                                            city={city}
                                                            cityOptions={cityOptions}
                                                            setCityOptions={setCityOptions}
                                                            setStateUf={setStateUf} stateUf={stateUf} trigger={trigger}
                                                            control={control} errors={errors} clearErrors={clearErrors}
                                                            setValue={setValue}
                                                            required={true}/>
                                                    </CardBody>
                                                </>
                                            }


                                            {cpfEncontrado &&

                                                <Row>
                                                    {data.bolsa === false  &&
                                                    <Pagador control={control} errors={errors} clearErrors={clearErrors}
                                                             setValue={setValue}
                                                             setError={setError}
                                                             loading={loading}
                                                             setLoading={setLoading}
                                                             register={register}
                                                             handlePagador={handlePagador}
                                                             cpfErrosMessage={cpfErrosMessage}
                                                    /> }


                                                    <Row>{!isSubmitting ?

                                                        <Col sm={12} className="d-flex justify-content-end py-3">
                                                            <Button type={'submit'} disabled={!isValid || loading}
                                                                    color="primary">Avançar <ArrowRight/> </Button>
                                                        </Col> :
                                                        <Col sm={12} className="d-flex justify-content-end py-3">
                                                            <Button type={'submit'} disabled={!isSubmitting}
                                                                    color="primary">Salvando... <ArrowRight/> </Button>
                                                        </Col>
                                                    }
                                                    </Row>

                                                </Row>


                                            }


                                        </Form>

                                    </Card>


                                </TabPane>
                                <TabPane tabId={2}>
                                    <Card>
                                        {contratoUrl ? <>

                                            <CardBody>
                                                <Row>
                                                    <Col className="vh-100">
                                                        <iframe
                                                            title={'Contrato IBREP'}
                                                            height="100%" width="100%"
                                                            src={`${contratoUrl}#view=fitH`}/>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <Row>
                                                    <Col sm={12}>
                                                        <div className="form-check mb-3">
                                                            <input {...register('terms_check')}
                                                                   onClick={(e) => handleTermsChange(e, 'terms_check')}
                                                                   className="form-check-input" type="checkbox" value=""
                                                                   id="invalidCheck" required/>
                                                            <label className="form-check-label" htmlFor="invalidCheck">
                                                                Declaro que conferi meus dados no Contrato de Prestação
                                                                de
                                                                Serviços Educacionais acima
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-3">
                                                            <input  {...register('terms_sign')}
                                                                    onChange={(e) => handleTermsChange(e, 'terms_sign')}
                                                                    className="form-check-input" type="checkbox"
                                                                    value=""
                                                                    id="gridCheck" required/>
                                                            <label className="form-check-label" htmlFor="gridCheck"> As
                                                                partes reconhecem como juridicamente válida a assinatura
                                                                por
                                                                meio eletrônico, digital, checkbox (assinalação online),
                                                                assim como qualquer outra forma de manifestação de
                                                                vontade
                                                                online do <a
                                                                    href={contratoUrl} target="_blank" rel="noreferrer">
                                                                    Contrato de Prestação de Serviços Educacionais - EAD
                                                                    e
                                                                    fornecimento de Material
                                                                    Pedagógico (Clique para baixar uma copia)</a>
                                                            </label>
                                                        </div>

                                                    </Col>
                                                    <Col sm={12} className={'d-flex justify-content-between'}>
                                                        <Button className="btn-light"
                                                                onClick={() => setActiveTab(1)}>Voltar</Button>
                                                        <Button
                                                            disabled={!data.terms_check || !data.terms_sign || loading}
                                                            color="primary"
                                                            onClick={handleContractAccept}
                                                        ><CheckSquare/> {loading ? 'SALVANDO...' : 'Aceitar Contrato' }</Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter> </> : <Spinner/>}
                                    </Card>
                                </TabPane>
                                {

                                    data.invoices.map((invoice, index) => {
                                        return <TabPane key={index} tabId={3 + index} id="v-pills-confir"
                                                        role="tabpanel">
                                            <Card>
                                                <Form onSubmit={handleSubmit((data) => handleCreditCard({
                                                    ...data,
                                                    index
                                                }), onError)}>
                                                    <CardBody className={'p-4'}>
                                                        {invoice.status === 'paid' ? <Row>

                                                            <Col sm={12} md={8}>
                                                                <div className="d-flex justify-content-end">
                                                                    <div>
                                                                        <h5>Pagamento {invoice.entrada ? ' da Entrada' : ''} Recebido
                                                                            com sucesso</h5>

                                                                        <ul className='list-unstyled mt-3'>
                                                                            <li>Pago com: {invoice.payable_with}</li>
                                                                            <li>Fatura: {invoice.items[0].description}</li>
                                                                            <li>Valor
                                                                                Pago: {formatter.format(invoice.total)}</li>
                                                                        </ul>
                                                                    </div>

                                                                    <dotlottie-player
                                                                        src="https://lottie.host/f5d209af-642b-46bf-aeb9-b4204b468a49/J1ajF4Y6vo.json"
                                                                        background="transparent" speed="1"
                                                                        style={{width: '300px', height: '200px'}}
                                                                        direction="1" mode="normal"
                                                                        autoplay></dotlottie-player>
                                                                </div>
                                                            </Col>
                                                        </Row> : <>

                                                            {invoice.payable_with === 'Pix' && <Row>
                                                                {invoice?.teopay?.pix?.encodedImage ?
                                                                    <Col sm={12} className="py-5">
                                                                        <Row>
                                                                            <Col sm={12} md={6}>
                                                                                <h6>Chave PIX</h6>
                                                                                <p>{invoice?.teopay?.pix?.payload} <br/>
                                                                                    <Button className="btn-success mt-3"
                                                                                            onClick={() => copy(invoice?.teopay?.pix?.payload)}>
                                                                                        Copiar <FontAwesomeIcon
                                                                                        icon={faCopy}/>
                                                                                    </Button>
                                                                                </p>
                                                                            </Col>
                                                                            <Col sm={12} md={6}>
                                                                                <p>
                                                                                    <img
                                                                                        src={`data:image/png;base64, ${invoice?.teopay?.pix?.encodedImage}`}
                                                                                        className="img-fluid pt-2"
                                                                                        alt={'QR Code do PIX'}/>
                                                                                </p>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                    :
                                                                    <Col sm={12}
                                                                         className="d-flex justify-content-center text-center py-5">
                                                                        <Button
                                                                            className="fw-bold"
                                                                            color="success"
                                                                            disabled={gerandoBoleto}
                                                                            onClick={() => generatePix(index)}
                                                                        >{gerandoBoleto ? <Spinner/> : <CheckSquare
                                                                            size={15}/>} {gerandoBoleto ? ' Gerando QR Code' : ' GERAR QR Code para pagamento'} </Button>
                                                                    </Col>
                                                                }
                                                            </Row>}
                                                            {invoice.payable_with === 'Pix Manual' && <Row>
                                                                {data.enrollment.idInstituicao === "6" ?
                                                                    <div>
                                                                        <Row>
                                                                            <Col sm={12}>
                                                                                <div className="d-flex mb-3">
                                                                                    <h5 className="me-3">Faça o PIX para
                                                                                        o CNPJ: <strong> CNPJ
                                                                                            08.767.310/0001-48</strong>
                                                                                    </h5>
                                                                                    <Button id="btn-pixpr"
                                                                                            className="btn-sm"
                                                                                            onClick={() => copy("08767310000148")}> Copiar </Button>
                                                                                    <UncontrolledPopover placement="top"
                                                                                                         trigger="focus"
                                                                                                         target='btn-pixpr'>
                                                                                        <PopoverHeader>Copiado!</PopoverHeader>
                                                                                    </UncontrolledPopover>
                                                                                </div>

                                                                            </Col>
                                                                        </Row>

                                                                    </div> :
                                                                    <Row>
                                                                        <Col sm={12}>
                                                                            <div className="d-flex mb-3">
                                                                                <h5 className="me-3">Faça o PIX para o
                                                                                    CNPJ: <strong> CNPJ
                                                                                        08.146.138/0001-05</strong></h5>
                                                                                <Button id="btn-pixsc"
                                                                                        className="btn-sm"
                                                                                        onClick={() => {
                                                                                            copy("08146138000105")

                                                                                        }}> Copiar </Button>
                                                                                <UncontrolledPopover placement="top"
                                                                                                     trigger="focus"
                                                                                                     target='btn-pixsc'>
                                                                                    <PopoverHeader>Copiado!</PopoverHeader>
                                                                                </UncontrolledPopover>
                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                }

                                                                <h3> Envie o comprovante do PIX no formulário
                                                                    abaixo</h3>
                                                                <div style={{
                                                                    position: 'relative',
                                                                    height: 0,
                                                                    maxWidth: '100%',
                                                                    minHeight: "800px",
                                                                    paddingBottom: '56.25%'
                                                                }}>
                                                                    <iframe
                                                                        src={"https://b24-dzll4o.bitrix24.site/crm_form_gl0df/?ID=" + dealId}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: '100%',
                                                                            height: '800px',
                                                                            border: 0
                                                                        }}
                                                                        allowFullScreen></iframe>
                                                                </div>
                                                            </Row>}
                                                            {invoice.payable_with === 'Cheque' && <Row>
                                                                <div style={{
                                                                    position: 'relative',
                                                                    height: 0,
                                                                    maxWidth: '100%',
                                                                    minHeight: "800px",
                                                                    paddingBottom: '56.25%'
                                                                }}>
                                                                    <iframe
                                                                        src={"https://b24-dzll4o.bitrix24.site/crm_form_iztzc/?ID=" + dealId}
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: '100%',
                                                                            height: '800px',
                                                                            border: 0
                                                                        }}
                                                                        allowFullScreen></iframe>
                                                                </div>
                                                            </Row>}
                                                            {invoice.payable_with === 'Cartão de crédito' &&
                                                                <>
                                                                    {invoice.rede?.returnCode ?
                                                                        <h4 className={"text-danger mb-3"}>
                                                                            {invoice.rede?.returnCode !== '00' ? invoice.rede?.returnCode + ' - ' + t(invoice.rede?.returnMessage) : ''}
                                                                        </h4> :
                                                                        <h4 className={"text-success"}>
                                                                            {invoice.rede?.returnCode === '00' ? 'Pagamento realizado com sucesso' : ''}
                                                                        </h4>
                                                                    }


                                                                    <CreditCard
                                                                        maxInstallments={invoice.maxInstallments}
                                                                        total={invoice.total}
                                                                        index={index}
                                                                        setValue={setValue}
                                                                        errors={errors}
                                                                        control={control}/>
                                                                </>

                                                            }
                                                            {invoice.payable_with === 'Boleto' &&
                                                                <Row>

                                                                    {invoice.iugu?.secure_url ?
                                                                        <Col sm={12} className="py-5">

                                                                            <Alert color="success">
                                                                                Seu Boleto foi gerado com sucesso! <Link
                                                                                to={invoice.iugu?.secure_url}
                                                                                target={'_blank'}
                                                                                className="fw-bolder" color={'success'}
                                                                            >Clique aqui para abrir seu boleto</Link> ou
                                                                                faça
                                                                                o pagamento lendo o código de barras
                                                                                abaixo
                                                                            </Alert>

                                                                            <h6>Linha digitável</h6>
                                                                            <p>{invoice.iugu?.bank_slip?.digitable_line}
                                                                                <Button className="btn-sm"
                                                                                        onClick={() => copy(invoice.iugu?.bank_slip.digitable_line)}> Copiar
                                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                                </Button>
                                                                                <img
                                                                                    src={invoice.iugu?.bank_slip.barcode}
                                                                                    className="img-fluid pt-2"
                                                                                    alt={'Código de barras do boleto'}/>
                                                                            </p>
                                                                        </Col>
                                                                        :
                                                                        <Col sm={12}
                                                                             className="d-flex justify-content-center text-center py-5">
                                                                            <Button className="fw-bolder"
                                                                                    color={'success'}
                                                                                    disabled={gerandoBoleto}
                                                                                    onClick={() => generateInvoice(index)}>{gerandoBoleto ?
                                                                                <Spinner/> : <CheckSquare
                                                                                    size={15}/>} {gerandoBoleto ? 'Gerando seu boleto' : ' Gerar Boleto'}</Button>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            }

                                                            {invoice.payable_with === 'Venda Pela Maquininha Física' &&
                                                                <Row>
                                                                    <div style={{
                                                                        position: 'relative',
                                                                        height: 0,
                                                                        maxWidth: '100%',
                                                                        minHeight: "800px",
                                                                        paddingBottom: '56.25%'
                                                                    }}>
                                                                        <iframe
                                                                            src={"https://b24-dzll4o.bitrix24.site/crm_form_5aqds/?ID=" + dealId}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: 0,
                                                                                left: 0,
                                                                                width: '100%',
                                                                                height: '800px',
                                                                                border: 0
                                                                            }}
                                                                            allowFullScreen></iframe>
                                                                    </div>
                                                                </Row>}
                                                        </>
                                                        }

                                                    </CardBody>

                                                    {invoice.status === 'paid' &&
                                                        <CardFooter>
                                                            <Row>
                                                                <Col sm={12} className={'d-flex justify-content-end'}>
                                                                    <Button
                                                                        onClick={() => setActiveTab(activeTab + 1)}
                                                                        className="fw-bold"
                                                                        color="success"
                                                                    >Avancar
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardFooter>
                                                    }
                                                    {(invoice.payable_with === 'Cartão de crédito' && invoice.status !== 'paid') &&
                                                        <CardFooter>
                                                            <Row>
                                                                <Col sm={12} className={'d-flex justify-content-end'}>
                                                                    <Button
                                                                        className="fw-bold"
                                                                        color="success"
                                                                        disabled={isSubmitting}
                                                                        onSubmit={handleCreditCard}
                                                                    >{isSubmitting ? <Spinner/> : <CheckSquare
                                                                        size={15}/>} {isSubmitting ? ' PROCESSANDO PAGAMENTO' : ' PAGAR'} </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardFooter>
                                                    }

                                                </Form>


                                            </Card>
                                        </TabPane>
                                    })
                                }

                                <TabPane tabId={5}>

                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="border rounded active shipping-address card">
                                                        <div className="card-body">
                                                            <Row className="align-items-center">
                                                                <Col>
                                                                    <h3 className="mb-2">Pagamento confirmado com
                                                                        sucesso</h3>

                                                                    <ul className="mt-2">
                                                                        <li><h5
                                                                            className="font-size-14 mb-2">{data.payer?.name ?? 'Aluno'}</h5>
                                                                        </li>
                                                                        <li><h5
                                                                            className="font-size-14 mb-2">CPF: {data.payer?.cpf_cnpj}</h5>
                                                                        </li>
                                                                        <li><h5
                                                                            className="font-size-14 mb-2">Telefone: {data.payer?.phone_complete}</h5>
                                                                        </li>
                                                                        <li><h5
                                                                            className="font-size-14 mb-2">E-mail: {data.payer?.email}</h5>
                                                                        </li>
                                                                        <li><h5
                                                                            className="font-size-14 mb-2">Contrato: <a
                                                                            href={data.contrato}
                                                                            target="_blank"
                                                                            rel="noreferrer">clique
                                                                            aqui para abrir</a></h5></li>
                                                                        <li><h5
                                                                            className="font-size-14 mb-2">Matrícula: <a
                                                                        >{data.oraculo?.response?.idmatricula}</a></h5>
                                                                        </li>
                                                                    </ul>


                                                                </Col>
                                                                <Col>
                                                                    <Carrinho col={12} title={false}/>

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </CardBody>
                                    </Card>


                                </TabPane>

                            </TabContent>
                        </Col>
                    </Row>
                </Col>

                {activeTab !== 5 && <Col md={4} sm={12}> <Carrinho col={12}/></Col>}
            </Row>

            {searchParams.get("debug") && <>

                <Row>
                    DEBUG
                    <Col>
                       <p>
                           is loading ? {JSON.stringify(loading)}
                       </p>
                        <p>
                            erros : {JSON.stringify(errors)}
                        </p>
                        <p>
                            isValid ? {JSON.stringify(isValid)}
                        </p>


                    </Col>
                </Row>
            </>}

        </Container>
    }
}


export default Checkout
