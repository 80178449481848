export const SexoOptions = [
    {
        "value": "F",
        "label": "Feminino"
    },
    {
        "value": "M",
        "label": "Masculino"
    }
]
