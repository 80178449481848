import firebase from 'firebase/compat/app';
import 'firebase/compat/app';
import 'firebase/compat/firestore';
import {firebaseConfig} from './firebaseConfig'

// TODO: Use a configuration object
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  db.useEmulator('localhost', 8080);
}

export default firebase;

export { db };


