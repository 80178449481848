import axios from 'axios'
import Transaction from 'erede-node/lib/transaction'

export const post = async (data) => {
    const infoRede = new Transaction (
        data.amount,Date.now(), data.installments?.value
    ).creditCard(
        data.cardNumber.replace(/\D/g, ''),
        data.cardCvc,
        data.cardExpiryDate.substring(0, data.cardExpiryDate.indexOf('/')).trim(),
        data.cardExpiryDate.substring(data.cardExpiryDate.indexOf('/') + 1, data.cardExpiryDate.length).trim(),
        data.cardholderName,
        data.installments ?? 1
    );

    return await axios.request({
        method: 'POST',
        data: {rede: infoRede, code: data.code, invoice_index: data.index},
        url: process.env.REACT_APP_REDE_URL,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });

}



