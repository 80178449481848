import {Card, CardBody, CardHeader, CardTitle, Col, FormFeedback, Input, Label, Row} from 'reactstrap'
import {useState} from 'react'
import InputCpf from '../../components/InputCpf'
import {InputCnpj} from '../../components/InputCnpj'
import {InputCep} from '../../components/InputCep'
import {cnpj, cpf} from 'cpf-cnpj-validator'
import {getCnpj, getCpf} from '../../services/HelperService'
import './style.scss'
import {handleCep, handleCidade} from '../../helpers/api_cep'
import {MapPin, Paperclip} from 'react-feather'
import classnames from 'classnames'

const Pagador = ({clearErrors, setError, control, errors, setValue, cpfErrosMessage, handlePagador, loading, setLoading}) => {

    const [mesmoPagador, setMesmoPagador] = useState(true)

    const [data, setData] = useState({
        tipo: 'juridica',
        email: null,
        nascimento: null
    })
    const handleDocumento = (e) => {

        const documento = e.target.value.replace(/\D/g, '')
        setData({
            ...data,
            documento: e.target.value,
            nascimento:null})

        if(data.tipo === 'fisica') {
            cpf.isValid(documento) ? clearErrors('billingPayer.documento') : setError('billingPayer.documento', {type: 'custom', message: 'Documento inválido'})
        } else {

            cnpj.isValid(documento) ?
                clearErrors('billingPayer.documento') :
                setError('billingPayer.documento', {type: 'custom', message: 'Documento inválido'})
        }

        handlePagador(data)
    }
    const handleData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

        handlePagador({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleEndereco = async (e) => {

        if(e.target.name ==='cep') {
            setData({...data, cep: e.target.value})

            if (e.target.value.length === 9) {
                setLoading(true)
                const address = await handleCep(e.target.value)
                if (address.hasOwnProperty('state')) {
                    setLoading(false)
                    setData({...data, address})
                    handlePagador({...data, address})

                    const cidade = await handleCidade(address.state, address.localidade)
                    if(cidade.hasOwnProperty('cidade')) {
                        setData({...data, address : {
                                ...address,
                                localidade: cidade.cidade
                            }})
                        handlePagador({...data, address : {
                                ...address,
                                localidade: cidade.cidade
                            }})
                    }

                } else {
                    setLoading(false)
                }


            }

        } else {
            setData({
                ...data,
                address: {
                    ...data.address,
                    [e.target.name] : e.target.value
                }
            })

            handlePagador({
                ...data,
                address: {
                    ...data.address,
                    [e.target.name] : e.target.value
                }
            })
        }

    }

    const completeCpf = () => {
        setLoading(true)
        const document = data.documento.replace(/\D/g, '')
        const nascimento = data.nascimento
        cpf.isValid(document) ? clearErrors('billingPayer.documento') : setError('billingPayer.documento', {type: 'custom', message: 'Documento inválido'})
        if(cpf.isValid(document)  ) {
            getCpf({
                cpf:document,
                data_nascimento: nascimento
            }).then(response => {
                setLoading(false)

                if(response.data?.status) {
                    setData({...data, name: response.data.result.nome_da_pf, pagador_encontrado: true})
                    setValue('name', response.data?.result?.nome_da_pf)
                } else {

                    if(cpfErrosMessage[response.data?.message] === 'ERRO') {
                        clearErrors('cpf_nascimento' )
                    } else {
                        setError('cpf_nascimento', cpfErrosMessage[response.data?.message] )
                    }
                    setLoading(false)

                }

            })
                .catch(error => {
                    console.log(error?.message)
                    setLoading(false)
                    setError('cpf_nascimento', 'CPF ou data de nascimento inválidos')
                })
        } else {
            setLoading(false)
        }

        handlePagador(data)
    }
    const completeCnpj = () => {
        setLoading(true)

        if(!data.documento) {
            setError('billingPayer.documento', {type: 'custom', message: 'Documento inválido'})
            setLoading(false)
            return
        }
        const document = data.documento.replace(/\D/g, '')
        cnpj.isValid(document) ?
            clearErrors('billingPayer.documento') :
            setError('billingPayer.documento', {type: 'custom', message: 'Documento inválido'})
        if(cnpj.isValid(document)  ) {
            getCnpj({
                cnpj:document
            }).then(response => {
                setLoading(false)

                if(response.data?.status) {
                    setData({...data,
                        name: response.data.result.nome,
                        email: response.data.result.email,
                        address: {
                            cep: response.data.result.cep,
                            numero: response.data.result.numero,
                            logradouro:response.data.result.logradouro,
                            bairro: response.data.result.bairro,
                            localidade: response.data.result.municipio,
                            state: response.data.result.uf,
                            complemento:response.data.result.complemento
                        },
                        pagador_encontrado: true})

                    handlePagador({...data,
                        name: response.data.result.nome,
                        email: response.data.result.email,
                        address: {
                            cep: response.data.result.cep,
                            numero: response.data.result.numero,
                            logradouro:response.data.result.logradouro,
                            bairro: response.data.result.bairro,
                            localidade: response.data.result.municipio,
                            state: response.data.result.uf,
                            complemento:response.data.result.complemento
                        },
                        pagador_encontrado: true})
                } else {

                    setError('documento', response.data?.message )
                    setLoading(false)

                }

            })
                .catch(error => {
                    console.log(error?.message)
                    setLoading(false)
                    setError('cpf_nascimento', 'CPF ou data de nascimento inválidos')
                })
        } else {
            setLoading(false)
        }


    }

     const handleMesmoPagador = (e) => {
            handlePagador(null)
            setValue('mesmo_pagador', e.target.checked)
            setMesmoPagador(e.target.checked)

         if(e.target.checked) {
             clearErrors('billingPayer.documento')
         }

    }

    return <>

        <Row>
            <Col xs={12}>
                <Card>
                    <CardHeader><h5><Paperclip size={16}/> Dados de Faturamento </h5></CardHeader>

                    <CardBody>

                        <Row className="mb-3">
                            <Col xs={12}>
                                <Input type="checkbox"
                                       name="mesmo_pagador"
                                       style={{
                                           width:'1.8em',
                                           height: '1.8em',
                                           verticalAlign: 'text-bottom'
                                       }}
                                       className="input-group-lg checkbox-wrapper-mail"
                                       checked={mesmoPagador}
                                       id="mesmo_pagador"
                                       onChange={handleMesmoPagador}
                                />
                                <Label className="mx-2 font-size-16" for="mesmo_pagador">Usar os mesmos dados do aluno</Label>
                            </Col>
                        </Row>
                        {!mesmoPagador &&

                            <>

                                <Row>
                                    <Col>
                                        <h5>Faturar para: </h5>
                                        <ul className="nav nav-pills pricing-nav-tabs mb-3 mt-3 d-flex justify-content-evenly">
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => setData({...data,
                                                        tipo: 'juridica',
                                                        name: null,
                                                        nascimento: null,
                                                        documento: null,
                                                        email: null,
                                                        address: null,
                                                    })}
                                                    className={'nav-link ' + classnames(data.tipo === 'juridica' ? 'active' : '')}>
                                                    Pessoa Jurídica
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    onClick={() => setData({
                                                        ...data, tipo: 'fisica',
                                                        name: null,
                                                        email: null,
                                                        nascimento: null,
                                                        documento: null,
                                                        address: null
                                                    })}
                                                    className={'nav-link ' + classnames(data.tipo === 'fisica' ? 'active' : '')}>
                                                    Pessoa Física
                                                </a>
                                            </li>
                                        </ul>

                                    </Col>

                                </Row>
                                <Row>

                                    <Col sm={8} className="mb-2">
                                        <Label>E-mail que deverá receber a nota fiscal*</Label>
                                        <Input type="text" name="email"
                                               invalid={errors.billingPayer?.email && true}
                                                  onChange={handleData}/>

                                        {errors.billingPayer?.email && (<FormFeedback
                                            className="d-block">{errors.billingPayer?.email?.message}</FormFeedback>)}

                                    </Col>

                                    {data.tipo === 'fisica' ?
                                        <>
                                            <Col sm={8} className="mb-2">
                                                <Label> CPF*</Label>
                                                <InputCpf name="documento"
                                                          invalid={errors.billingPayer?.documento && true}
                                                          handleChange={handleDocumento}/>

                                                {errors.billingPayer?.documento && (<FormFeedback
                                                    className="d-block">{errors.billingPayer?.documento?.message}</FormFeedback>)}
                                            </Col>
                                            <Col sm={4} className="mb-2">
                                                <Label for="nascimento">Data de nascimento</Label>
                                                <Input type="date"
                                                       name="nascimento"
                                                       onBlur={completeCpf}
                                                       onChange={handleData}
                                                       invalid={errors.nascimento && true}
                                                       required={true}/>
                                                {errors.nascimento && (<FormFeedback
                                                    className="d-block">{errors.nascimento?.message}</FormFeedback>)}
                                            </Col>
                                            <Col sm={12} className="mb-2">
                                                <Label>Nome completo</Label>
                                                <Input type={'text'}
                                                       value={data.name}
                                                       readOnly={data.pagador_encontrado}
                                                       onChange={(e) => setData({
                                                           ...data,
                                                           name: e.target.value
                                                       })}
                                                       name="name"/>
                                            </Col>

                                            <Col sm={12} className="mb-2">
                                                <Label>CEP*</Label>
                                                <InputCep name="cep"
                                                          invalid={errors.billingPayer?.address?.cep && true}
                                                          onChange={handleEndereco}/>
                                                {errors.billingPayer?.address?.cep && (<FormFeedback
                                                    className="d-block">{errors.billingPayer?.address?.cep?.message}</FormFeedback>)}
                                            </Col>
                                            <Col sm={8} className="mb-2">
                                                <Label>Endereço</Label>
                                                <Input
                                                    type={'text'}
                                                    readOnly={true}
                                                    value={data.address?.logradouro}
                                                    name="logradouro" onChange={handleEndereco}/>
                                            </Col>
                                            <Col sm={4} className="mb-2">
                                                <Label>Bairro</Label>
                                                <Input
                                                    type={'text'}
                                                    readOnly={true}
                                                    value={data.address?.bairro}
                                                    name="bairro" onChange={handleEndereco}/>
                                            </Col>
                                            <Col className="mb-2">
                                                <Label>Número</Label>
                                                <Input type={'text'} name="numero"
                                                       invalid={errors.billingPayer?.address?.numero && true}
                                                       value={data.address?.numero}  onChange={handleEndereco}/>
                                                {errors.billingPayer?.address?.numero && (<FormFeedback
                                                    className="d-block">{errors.billingPayer?.address?.numero?.message}</FormFeedback>)}
                                            </Col>
                                            <Col className="mb-2">
                                                <Label>Complemento</Label>
                                                <Input type={'text'} name="complemento" value={data.address?.complemento}  onChange={handleEndereco}/>
                                            </Col>
                                            <Row>
                                                <Col className="mb-2">
                                                    <Label>Cidade</Label>
                                                    <Input
                                                        readOnly={true}
                                                        value={data.address?.localidade}
                                                        type={'text'} name="localidade"/>
                                                </Col>
                                                <Col className="mb-2">
                                                    <Label>Estado</Label>
                                                    <Input type={'text'}
                                                           readOnly={true}
                                                           value={data.address?.state}
                                                           name="state"/>
                                                </Col>

                                            </Row>

                                        </> : <>

                                            <Col sm={12} className="mb-2">
                                                <Label> CNPJ</Label>
                                                <InputCnpj name="documento"
                                                           onBlur={completeCnpj}
                                                           value={data.documento}
                                                           handleChange={handleDocumento}
                                                           invalid={errors.billingPayer?.documento && true}


                                                />
                                                {errors.billingPayer?.documento && (<FormFeedback
                                                    className="d-block">{errors.billingPayer?.documento?.message}</FormFeedback>)}
                                            </Col>
                                            <Col sm={12} className="mb-2">
                                                <Label>Razão Social</Label>
                                                <Input type={'text'}
                                                       name="name"
                                                       value={data.name}
                                                       onChange={(e) => setData({
                                                           ...data,
                                                           name: e.target.value
                                                       })}
                                                       readOnly={true}
                                                />
                                            </Col>
                                        </>

                                    }


                                </Row>
                            </>}

                    </CardBody>
                </Card>

            </Col>
        </Row>

    </>

}

export default Pagador
