import axios from 'axios'

export const post = async (data) => {

    return await axios.request({
        method: 'POST',
        data,
        url:  process.env.REACT_APP_TEOPAY_URL,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });

}

