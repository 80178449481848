import React, { useRef } from 'react'
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import {t} from 'i18next'
import pt from 'react-phone-input-2/lang/pt.json'

export const InputTel  = React.forwardRef(({
                                           type = 'tel',
                                           name,
                                           value,
                                           className,
                                           autoComplete,
                                           required,
                                           onChange,
                                           invalid,
                                       }, ref) => (
    <PhoneInput
        ref={ref}
        isValid={(value, country) => {
            if (value.length < 13 && country.name === 'Brazil' && invalid) {
                return 'Número inválido'
            } else {
                return true;
            }
        }}
        country={'br'}
        masks={{br: '(..) .....-....'}}
        inputClass='form-control'
        onlyCountries={['br', 'ar', 'pt', 'us']}
        countryCodeEditable={false}
        localization={pt}
        enableSearch={true}
        value={value}
        searchPlaceholder={t('Search')}
        inputProps={{
            name: name,
            required: required,
            autoFocus: false,
            invalid: invalid
        }}
        placeholder=''
        name={name}
        onChange={(e) => onChange(e)}
    />

));
