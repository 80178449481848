import {useCreditCardValidator, images} from 'react-creditcard-validator'
import {Col, FormFeedback, Input, InputGroup, Label, Row} from 'reactstrap'
import {Controller} from 'react-hook-form'
import Select from 'react-select'
import valid from "card-validator"
import InputMask from 'react-input-mask'
import {useEffect} from 'react'

const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
})
const CreditCard = ({control, errors, maxInstallments, total}) => {

    let installments = []

    for (var i = 1; i <= maxInstallments; i++) {
        installments.push({value: i, label: `${i} x ${formatter.format(total / i)}`})
    }
    function expDateValidate(month, year) {
        if (Number(year) > 2040) {
            return 'Data de validade não parece estar correta'
        }
        return
    }
    function cardValidate(number) {
        if(number.length === 15 && (number.substring(0,2) === 37 || number.substring(0,2) === 34)) {
            console.log(number)
            return true
        }
    }

    const {
        getExpiryDateProps,
        meta: {erroredInputs}
    } = useCreditCardValidator({
        expiryDateValidator: expDateValidate,
        cardNumberValidator: cardValidate
    })

    return (
        <Row>
            <Col sm={12} md={12} className="mb-2">
                <Label>Titular do cartão*</Label>
                <Controller
                    name={'cardholderName'}
                    control={control}
                    render={({field}) => (
                        <Input id="cardholderName" type={'text'} required
                               invalid={errors.cardholderName && true} {...field}/>
                    )}
                />
                {errors.cardholderName && <FormFeedback>Nome do titular do cartão é obrigatório</FormFeedback>}
            </Col>
            <Col sm={12} md={12} className="mb-2">
                <Label>Número do cartão</Label>

                <Controller
                    name={'cardNumber'}
                    control={control}
                    render={({field}) => (
                        <InputGroup className={'input-group-creditcard'}>
                            <InputMask maskChar="" mask={"9999 9999 9999 9999"} type="tel" onChange={field.onChange} className="creditcard" />
                        </InputGroup>
                    )}
                />
                <small
                    className={'text-danger'}>{errors.cardNumber && 'Número de cartão de crédito inválido'}</small>
            </Col>
            <Col sm={12} md={6} className="mb-2">
                <Label>Validade</Label>
                <Controller
                    name={'cardExpiryDate'}
                    control={control}
                    render={({field}) => (
                        <InputGroup className={'input-group-creditcard'}>
                            <input
                                   className="creditcard" {...getExpiryDateProps({onChange: field.onChange})} />
                            <small
                                className={'text-danger'}>{erroredInputs.expiryDate && 'Data de expiração inválida'}</small>
                        </InputGroup>
                    )}
                />

            </Col>
            <Col sm={12} md={6} className="mb-2">
                <Label>CVV</Label>
                <Controller
                    name={'cardCvc'}
                    control={control}
                    render={({field}) => (
                        <InputGroup className={'input-group-creditcard'}>
                           <Input type={"tel"} maxLength={4} {...field} />
                            <small>{erroredInputs.cvc && 'Código de segurança inválido'}</small>
                        </InputGroup>
                    )}
                />
            </Col>
            <Col sm={12} md={6} className="mb-2">
                <Label>Parcelamento</Label>
                <Controller
                    name={'installments'}
                    control={control}
                    render={({field}) => (
                      <Select options={installments} {...field} required={true} />
                    )}
                />
            </Col>
        </Row>
    )
}

export default CreditCard
