import axios from 'axios'

export const enroll = async (dealId) => {


    return await axios.request({
        method: 'GET',
        params: {
            dealId: dealId,
            token: "82x5vftc7xfzwblfufen8qsxbx4mia4b"
        },
        url: process.env.REACT_APP_ORACULO_URL,
        withCredentials: false,
        headers: {accept: 'application/json', 'content-type': 'application/json'}
    });

}



