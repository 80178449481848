// Checkout
import Checkout from '../pages/Checkout'
import Home from '../pages/Home'


const publicRoutes = [
	{
		path: "/:code",
		component: <Checkout />
	},
	{
		path: "/",
		component: <Home />
	}

];

export { publicRoutes };
